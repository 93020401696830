@import "vars";
@import "bootstrap-5.3.0-alpha1/scss/bootstrap.scss";
@import url('https://fonts.googleapis.com/css?family=Open+Sans:400,600,400italic,600italic|Roboto+Slab:400,700');

/* Custom Styles */

$backgroundColor: #FFF;

$bodyColor: #000000;

$bodyFont: -apple-system,BlinkMacSystemFont,"Segoe UI",Helvetica,Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol";

body {
  background: $backgroundColor;
  color: $bodyColor;
  font-family: $bodyFont;
  font-size: large;
}


h1, h2, h3, h4, h5, h6 {
  font-family: "Roboto Slab";
  line-height: 2.5;
}

#logo {
  padding-right: 1rem;
}

#home-text {
  font-style: italic;
  text-align: center;
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.nav-link {
  white-space: nowrap;
}
.navbar-expand-lg .navbar-collapse {
flex-basis: -moz-available;
}

footer {
  height: 300px;
  margin-top: 5rem
}

#footimg {
  height: 300px;
  float: right;
  padding-right: 50px
}

.card {
    height: 30rem;
    margin-bottom: 2rem;
}

.card-img-top {
    height: 15rem;
    width: 100%;
}

.image_fit_arch{
   width: 15vw;
   height: 15vh;
}