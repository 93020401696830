@import "main";

.metadata {
	font-family: "Roboto Slab"
}
.toc {
		font-family: "Roboto Slab"
}

div#borb img {
  max-width: 70%;
}

.home-page-image {
	width: 400px;
}